/** Road **/

import React from "react";
import { gsap } from "gsap"
import { frameAnimation, preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import Preloader from "../../components/preloader/preloader"
import Loader from "../../components/preloader/loader"


let globalState, data, preloadCounter = 0;

class TownTitlePage extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[0];

    this.timeout1 = null;

    //refs
    this.titleRef = null;
  }

  state = {
    isActivated: false,
    loadNum: 0,
    totalLoadNum: 0
  }

  componentDidMount = () => {

    //check if is active
    this.setState({ isActivated: globalState.isActivated });

    //remove intro basetrack
    globalState.baseAudioRef.updateTrack(1, false); 

    //If yes animate in title
    if (globalState.isActivated) {
      this.initTitle();
    }

  }


  initTitle = () => {

    //1. Set isActivated locally and globally
    this.setState({ isActivated: true });
    globalState.updateIsActivated(true);

    
    
    // add town base track
    globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);


    //3. Animate in Title
    this.timeout1 = setTimeout(function () {
      gsap.to(this.titleRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.titleRef, 289, 179, 7, 4, 25, 0.2, 0);
    }.bind(this), 250);


    //4. Preload assets from next page
    const preloadData = globalState.assets[1];

    preloadImages([
      globalState.cdnUrl + preloadData.background,
      globalState.cdnUrl + preloadData.townText,
      globalState.cdnUrl + preloadData.townMap,
      globalState.cdnUrl + preloadData.townItem1,
      globalState.cdnUrl + preloadData.townItem2,
      globalState.cdnUrl + preloadData.townItem3,
      globalState.cdnUrl + preloadData.townItem5,
      globalState.cdnUrl + preloadData.townItem6,
      globalState.cdnUrl + preloadData.townItem7,
      globalState.cdnUrl + preloadData.townItem8,
      globalState.cdnUrl + preloadData.building1,
      globalState.cdnUrl + preloadData.building2,
      globalState.cdnUrl + preloadData.building3,
      globalState.cdnUrl + preloadData.building4,
      globalState.cdnUrl + preloadData.building5,
      globalState.cdnUrl + preloadData.building6,
      globalState.cdnUrl + preloadData.building7,
      globalState.cdnUrl + preloadData.building8,
      globalState.cdnUrl + preloadData.person1,
      globalState.cdnUrl + preloadData.person2,
      globalState.cdnUrl + preloadData.person3,
      globalState.cdnUrl + preloadData.person4,
      globalState.cdnUrl + preloadData.person5,
      globalState.cdnUrl + preloadData.person6,
      globalState.cdnUrl + preloadData.person7,
      globalState.cdnUrl + preloadData.tree1,
      globalState.cdnUrl + preloadData.tree2,
      globalState.cdnUrl + preloadData.tree3,
      globalState.cdnUrl + preloadData.tree4,
      globalState.cdnUrl + preloadData.tree5,
      globalState.cdnUrl + preloadData.tree6,
      globalState.cdnUrl + preloadData.tree7,

    ], this.preloadCallback, true);
  }

  preloadCallback = (loadedImgNum, totalImgNum) => {
    preloadCounter++;

    //update loader properties in state
    this.setState({ loadNum: preloadCounter });
    this.setState({ totalLoadNum: totalImgNum });
  }

  //Navigate to the next page after preloading is complete
  goNext = () => {
    setTimeout(() => {
      if(globalState.transitionDirection === "prev") {
        this.refs.prevNextRef.showPrevNextBtns();
      } else {
        this.refs.prevNextRef.goNext("/town/1", "slide");
      }
    }, 500);
  }


  componentDidUpdate() {
    //console.log("town component did update");
  }

  componentWillUnmount() {

    //Remove base track
    //globalState.baseAudioRef.updateTrack(2, false);

  }






  render() {

    return (

      <>

        <link rel="prefetch" href="/town/1" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/town/1" prevLocation="/intro/4"
        />
        {this.state.isActivated ?
          <div className="fullpage-wrapper bg--white flex center">
            <SEO title="Pine Point - Town" />

            <div className="chapterTitle" ref={div => this.titleRef = div} alt="Town" style={{ width: "289px", height: "179px", backgroundImage: `url(${globalState.cdnUrl + data.title})` }} />

            <Loader state={this.state} callback={this.goNext} />

          </div> :
          <Preloader ref="preloaderRef" globalState={globalState} data={data} callback={this.initTitle} />
        }


      </>
    );
  }


};

export default TownTitlePage
